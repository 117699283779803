.customer-service-landing-contact-block__content .customer-service-landing-contact-block {
  &__content {
    &-tout {
      text-align: $ldirection;
      flex: 100%;
      line-height: 2;
      &__headline {
        font-size: 22px;
        margin: 0 0 20px;
      }
      &__copy {
        max-width: 500px;
        line-height: 2;
      }
    }
  }
}

.customer-service-landing-contact-block {
  &__title {
    display: none;
  }
}
